// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_math from "../../../../../shared/node_modules/rescript/lib/es6/js_math.js";
import * as DOMPurify from "../shared/utils/DOMPurify.bs.js";
import * as Belt_Option from "../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../shared/utils/OptionUtils.bs.js";
import * as QrcodeReact from "qrcode.react";
import * as IssuedCertificate from "../shared/types/IssuedCertificate.bs.js";

function str(prim) {
  return prim;
}

function paddingPercentage(issuedCertificate) {
  return String(IssuedCertificate.margin(issuedCertificate)) + "%";
}

function certificateContainerStyle(issuedCertificate) {
  return {
          padding: paddingPercentage(issuedCertificate)
        };
}

function issuedToStyle(issuedCertificate) {
  return {
          top: String(IssuedCertificate.nameOffsetTop(issuedCertificate)) + "%"
        };
}

function qrCodeStyle(issuedCertificate) {
  return {
          padding: paddingPercentage(issuedCertificate)
        };
}

function nameCanvasId(issuedCertificate) {
  return "name-canvas-" + IssuedCertificate.serialNumber(issuedCertificate);
}

function nameCanvas(issuedCertificate) {
  return React.createElement("canvas", {
              className: "absolute top-0 w-full",
              id: "name-canvas-" + IssuedCertificate.serialNumber(issuedCertificate),
              style: issuedToStyle(issuedCertificate),
              height: "100",
              width: "2000"
            });
}

function qrPositionClasses(issuedCertificate) {
  var match = IssuedCertificate.qrCorner(issuedCertificate);
  if (match === "TopRight") {
    return "top-0 end-0";
  } else if (match === "BottomLeft") {
    return "bottom-0 start-0";
  } else if (match === "BottomRight") {
    return "bottom-0 end-0";
  } else if (match === "TopLeft") {
    return "top-0 start-0";
  } else {
    return "hidden";
  }
}

function qrContainerStyle(issuedCertificate) {
  var widthPercentage = IssuedCertificate.qrScale(issuedCertificate) / 100.0 * 10.0;
  return {
          width: widthPercentage.toString() + "%"
        };
}

function certificateUrl(issuedCertificate) {
  var prefix = window.location.origin;
  var suffix = "/c/" + IssuedCertificate.serialNumber(issuedCertificate);
  return prefix + suffix;
}

function qrCode(issuedCertificate, verifyImageUrl) {
  var match = IssuedCertificate.qrCorner(issuedCertificate);
  if (match === "Hidden") {
    return null;
  } else {
    return React.createElement("div", {
                className: "absolute " + qrPositionClasses(issuedCertificate),
                style: qrContainerStyle(issuedCertificate)
              }, React.createElement(QrcodeReact.QRCodeCanvas, {
                    value: certificateUrl(issuedCertificate),
                    size: 256,
                    bgColor: "transparent",
                    level: "Q",
                    imageSettings: {
                      src: verifyImageUrl,
                      height: 29,
                      width: 133,
                      excavate: true
                    },
                    style: {
                      height: "100%",
                      width: "100%"
                    },
                    className: "w-full h-full"
                  }));
  }
}

function drawName(issuedCertificate) {
  var canvasId = "name-canvas-" + IssuedCertificate.serialNumber(issuedCertificate);
  var canvasElement = document.getElementById(canvasId);
  var ctx = Belt_Option.map((canvasElement == null) ? undefined : Caml_option.some(canvasElement), (function (el) {
          return el.getContext("2d", {
                      alpha: true
                    });
        }));
  Belt_Option.forEach(ctx, (function (ctx) {
          ctx.clearRect(0.0, 0.0, 2000.0, 100.0);
        }));
  var fontSize = 50.0 * (IssuedCertificate.fontSize(issuedCertificate) / 100.0);
  OptionUtils.map((function (ctx) {
          ctx.font = String(Js_math.floor_int(fontSize)) + "px Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace";
        }), ctx);
  OptionUtils.map((function (ctx) {
          ctx.textAlign = "center";
        }), ctx);
  OptionUtils.map((function (ctx) {
          ctx.textBaseline = "middle";
        }), ctx);
  OptionUtils.map((function (ctx) {
          ctx.fillText(DOMPurify.sanitize(IssuedCertificate.profileName(issuedCertificate)), 1000.0, 50.0, undefined);
        }), ctx);
}

function IssuedCertificate__Root(Props) {
  var issuedCertificate = Props.issuedCertificate;
  var verifyImageUrl = Props.verifyImageUrl;
  React.useEffect((function () {
          drawName(issuedCertificate);
        }), [IssuedCertificate.fontSize(issuedCertificate)]);
  return React.createElement("div", {
              className: "relative w-full h-full"
            }, React.createElement("img", {
                  className: "w-full object-contain m-0 h-auto",
                  src: IssuedCertificate.imageUrl(issuedCertificate)
                }), React.createElement("div", {
                  className: "absolute top-0 start-0 w-full h-full",
                  style: certificateContainerStyle(issuedCertificate)
                }, React.createElement("div", {
                      className: "relative w-full h-full"
                    }, nameCanvas(issuedCertificate), qrCode(issuedCertificate, verifyImageUrl))));
}

var make = IssuedCertificate__Root;

export {
  str ,
  paddingPercentage ,
  certificateContainerStyle ,
  issuedToStyle ,
  qrCodeStyle ,
  nameCanvasId ,
  nameCanvas ,
  qrPositionClasses ,
  qrContainerStyle ,
  certificateUrl ,
  qrCode ,
  drawName ,
  make ,
}
/* react Not a pure module */
